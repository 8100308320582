<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">

        <b-card v-if="productData">
          <h3 class="text-primary mb-2">
            Season abundance
          </h3>
          <b-table
              class="position-relative"
              :items="productData"
              responsive
              :fields="myTableColumns"
              primary-key="id"
              show-empty
              bordered
              striped
              empty-text="No Products were found"
          >

            <template #cell(fromDate)="data">
              {{ new Date(data.item.fromDate).toLocaleDateString("en-US") }}
            </template>

            <template #cell(toDate)="data">
              {{ new Date(data.item.toDate).toLocaleDateString("en-US") }}
            </template>

            <template #cell(level)="data">
              <span v-if="data.item.level === 1">
                High
              </span>
              <span v-if="data.item.level === 2">
                Mid
              </span>
              <span v-if="data.item.level === 3">
                Low
              </span>
              <span v-if="data.item.level === 4">
                Off
              </span>
            </template>

            <template #cell(delete)="data">
              <b-link v-b-modal.modal-delete @click="selectedToDelete = data.item.id">
                <feather-icon icon="TrashIcon" class="text-danger" size="20"/>
              </b-link>
            </template>

          </b-table>
        </b-card>

        <b-row>
          <b-col cols="12">
            <b-card class="col-12">
              <b-row>
                <h4 class="text-primary col-12">New Data</h4>
                <b-col cols="12" md="4">
                  <b-form-group
                      class="mb-2"
                      label="City"
                  >
                    <b-form-input
                        v-model="newData.cityName"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="From Date"
                      label-for="fromDate"
                  >
                    <b-form-input
                        style="margin-top: .75rem"
                        id="fromDate"
                        :value="newData.fromDate"
                    ></b-form-input>
                    <custom-date-picker
                        v-model="newData.fromDate"
                        class=""
                        color="#5c6bc0"
                        element="fromDate"
                        format="YYYY/M/D"
                        locale="en"
                        type="date"
                    ></custom-date-picker>
                  </b-form-group>
                </b-col>

                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="To Date"
                      label-for="toDate"
                  >
                    <b-form-input
                        style="margin-top: .75rem"
                        id="toDate"
                        :value="newData.toDate"
                    ></b-form-input>
                    <custom-date-picker
                        v-model="newData.toDate"
                        class=""
                        color="#5c6bc0"
                        element="toDate"
                        format="YYYY/M/D"
                        locale="en"
                        type="date"
                    ></custom-date-picker>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                  <b-form-group label="Level" label-for="type">
                    <v-select
                        v-model="newData.level"
                        :options="typeOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="type"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      class="col-12 col-md-2"
                      variant="primary"
                      @click="addData"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
    </b-row>

    <b-modal
        id="modal-delete"
        centered
        ok-title="Delete"
        cancelTitle="Cansel"
        @ok="deleteData"
    >
      <span>Are you sure?</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {required} from '@validations';
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import ckEditorMain from "@/views/components/ckEditorMain.vue";
import ProductCategoryProperty from "@/views/apps/product/ProductCategoryProperty.vue";
import {
  AbunanceCreate, AbunanceDelete,
  AbunanceSeasonsGetByProductId,
} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  title: 'Season of abundance',
  data() {
    return {
      required,
      baseUrl:Helper.baseUrl,
      languageList: [],
      overlay: false,
      categories: [],
      productData: null,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      newPrice:null,
      myTableColumns: [
        {
          key: "cityName",
          label: "City",
        },
        {
          key: "fromDate",
          label: "From Date",
        },
        {
          key: "toDate",
          label: "To Date",
        },
        {
          key: "level",
          label: "Level",
        },
        {
          key: "delete",
          label: "Delete",
        },
      ],
      units: [],
      currencies: [],
      newData:{
        fromDate: "",
        toDate: "",
        cityName: "",
        level: 1,
        productId: this.$route.params.id
      },
      typeOptions: [
        {
          label: "High",
          value: 1,
        },
        {
          label: "Mid",
          value: 2,
        },
        {
          label: "Low",
          value: 3,
        },
        {
          label: "Off",
          value: 4,
        },
      ],
      selectedToDelete:null,
    }
  },
  async created() {
    await this.getAllProductData();
  },
  methods: {
    async getAllProductData() {
      try {
        this.overlay = true;
        let _this = this;
        let qParams = {
          productId:_this.$route.params.id
        };
        let abunanceSeasonsGetByProductId = new AbunanceSeasonsGetByProductId(_this);
        abunanceSeasonsGetByProductId.setParams(qParams);
        await abunanceSeasonsGetByProductId.fetch((response) => {
          if (response.isSuccess) {
            _this.productData = response.data.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.overlay = false;
      }
    },
    async addData() {
      let _this = this;
      _this.overlay = true;

      _this.newData.fromDate = new Date(_this.newData.fromDate);
      _this.newData.toDate = new Date(_this.newData.toDate);

      let abunanceCreate = new AbunanceCreate(_this);
      abunanceCreate.setParams(_this.newData);
      await abunanceCreate.fetch((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getAllProductData();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async deleteData() {
      let _this = this;
      _this.overlay = true;

      let abunanceDelete = new AbunanceDelete(_this);
      abunanceDelete.setParams({id:_this.selectedToDelete});
      await abunanceDelete.fetch((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getAllProductData();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
  },
  components: {
    ckEditorMain,
    AddEditDeleteButton,
    LanguagePicker,
    BModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BTable,
    BPagination,
    ValidationObserver, ValidationProvider,
    ProductCategoryProperty,
  },
}
</script>

<style>
.modal-header > .close {
  display: none !important;
}
</style>